.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: bg 10s;
  background-color: #fff;
  /* -webkit-animation: spin 10s ease-in-out infinite; */
}

